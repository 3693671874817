import { useEffect, useState } from "react";
import './Carousel.styles.scss';
import { GenericObject } from "@/models/UtilsModel";

const Card = (props: GenericObject) => {
    return (
        <li onMouseOver={() => props.onHover(props.currentElement)} className={"card " + ((props.currentElement.isSelected) ? 'selected' : '')}>
            <img src={props.image} alt="" />
        </li>
    )
}

const Carousel = ({ ...props }) => {
    const [moveClass, setMoveClass] = useState('');
    const [carouselItems, setCarouselItems] = useState<any>(props.items ?? []);

    useEffect(() => {
        onImageHover(props.items[0] ?? []);
    }, [])

    useEffect(() => {
        document.documentElement.style.setProperty('--num', carouselItems.length.toString());
    }, [carouselItems])

    const handleAnimationEnd = () => {
        if (moveClass === 'prev') {
            shiftNext([...carouselItems]);
        } else if (moveClass === 'next') {
            shiftPrev([...carouselItems]);
        }
        setMoveClass('')
    }

    const shiftPrev = (copy: CarouselItem[]) => {
        let lastcard = copy.pop();
        (lastcard) ? copy.splice(0, 0, lastcard) : null;
        setCarouselItems(copy);
    }

    const shiftNext = (copy: CarouselItem[]) => {
        let firstcard = copy.shift();
        (firstcard) ? copy.splice(copy.length, 0, firstcard) : null;
        setCarouselItems(copy);
    }

    const onImageHover = (selectedElement: GenericObject) => {
        props.notifySelection(selectedElement);
        const listWithNewSelection = carouselItems.map((ci: CarouselItem) => {
            ci.isSelected = (ci.id == selectedElement.id) ? true : false;
            return ci;
        })
        setCarouselItems(listWithNewSelection);
    }

    return (
        <div id="carousel">
            <button onClick={() => setMoveClass('next')} className="prev">
                <span className="material-icons">chevron_left</span>
            </button>
            <div className="carouselwrapper module-wrapper">
                <ul onAnimationEnd={handleAnimationEnd} className={`${moveClass} carousel`}>
                    {carouselItems?.map((t: CarouselItem, index: number) =>
                        <Card onHover={onImageHover} key={t.image + index} image={t.image} currentElement={t} />
                    )}
                </ul>
            </div>
            <button onClick={() => setMoveClass('prev')} className="next">
                <span className="material-icons">chevron_right</span>
            </button>
        </div>

    )
}

export default Carousel;
