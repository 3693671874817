"use client"

import * as React from "react"

import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { usePathname } from "next/navigation"
import { useRouter } from "next/router"
import { MenuIcon } from 'lucide-react';

export function HeaderDropdownMenu({ source = [] }: any) {
    const pathname = usePathname();
    const router = useRouter();

    const [position, setPosition] = React.useState("bottom");

    const [currentValue, setCurrentValue] = React.useState(pathname ?? '');

    console.log(router.asPath.split('/')[1])

    return (
        <div>
            {
                ((router.asPath.split('/')[1] === 'administration')) ?
                    <Button
                        variant="outline" className="rounded-2xl h-7"
                        onClick={() => {
                            setCurrentValue(source?.[1]?.href);
                            router.push(source?.[1]?.href);
                        }}
                    >
                        <MenuIcon className="w-4 h-4 mr-1"></MenuIcon>
                        Shop
                    </Button>
                    :
                    null
            }
            {
                ((router.asPath.split('/')[1] === 'catalogue')) ?
                    <Button
                        variant="outline" className="rounded-2xl h-7"
                        onClick={() => {
                            setCurrentValue(source?.[0]?.href);
                            router.push(source?.[0]?.href);
                        }}
                    >
                        <MenuIcon className="w-4 h-4 mr-1"></MenuIcon>
                        Admin
                    </Button>
                    :
                    null
            }
        </div>
    )

    /* return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" className="rounded-2xl h-7"><MenuIcon className="w-4 h-4 mr-1"></MenuIcon>
                    {
                        (router.asPath.split('/')[1] === 'administration') ? 'Admin'
                            :
                            ((router.asPath.split('/')[1] === 'catalogue') ? 'Shop' : 'Menu')
                    }
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                {
                    source?.map((menuItem: any, index: number) => {
                        return (
                            <DropdownMenuCheckboxItem
                                key={index + '-' + menuItem.href}
                                checked={(menuItem.href.includes((router.asPath.split('/')[1]) ? router.asPath.split('/')[1] : null))}
                                onCheckedChange={(isChecked) => {
                                    if (isChecked) {
                                        setCurrentValue(menuItem.href);
                                        router.push(menuItem.href);
                                    }
                                }}
                            >
                                {menuItem.label}
                            </DropdownMenuCheckboxItem>
                        )
                    })
                }
            </DropdownMenuContent>
        </DropdownMenu>
    ) */
}
