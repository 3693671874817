"use client"

import { Cross1Icon, Cross2Icon } from "@radix-ui/react-icons"
import { ColumnDef, Table, filterFns } from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { DataTableViewOptions } from "./data-table-view-options"
import { DataTableFacetedFilter } from "./data-table-faceted-filter"
import { GenericObject } from "@/models/UtilsModel"
import { DataTableDropdownFilter } from "./data-table-dropdown-filter"
import { useEffect } from "react"
import { X } from "lucide-react"

interface DataTableToolbarProps<TData, TValue> {
    table: Table<TData>
    columns: ColumnDef<TData, TValue>[]
    columnsCfg: GenericObject,
    tableDictionary?: GenericObject | null,
    view?: 'table' | 'cards'
}

export function DataTableToolbar<TData, TValue>({
    table,
    columns,
    columnsCfg,
    tableDictionary,
    view
}: DataTableToolbarProps<TData, TValue>) {
    const isFiltered = table.getState().columnFilters.length > 0 || table.getState().sorting.length > 0;

    const columnsTypeFilter: GenericObject[] = columnsCfg.columns.filter((column: GenericObject) => column.type === "filter" && column.filter === true);
    const columnsDropdownFilter: GenericObject[] = columnsCfg.columns.filter((column: GenericObject) => column.type === "dropdown-filter" && column.filter === true);
    const columnsBaseFilter: GenericObject[] = columnsCfg.columns.filter((column: GenericObject) => column.type === "base" && column.filter === true);

    useEffect(() => {
    }, [])

    return (
        <div className="flex items-center justify-between space-x-2 flex-row">
            <div className="flex flex-1 flex-row flex-wrap items-center justify-between text-left w-full mb-2">
                <div className="flex flex-row flex-wrap items-center text-left flex-1">
                    {
                        columnsBaseFilter &&
                        columnsBaseFilter.map((column: GenericObject, index: number) => {
                            return (
                                <Input
                                    placeholder={tableDictionary?.columns?.[column.name]?.filters?.placeholder ?? column.filterPlaceholder}
                                    value={((table.getColumn(column.name)?.getFilterValue() as string) ?? "")}
                                    onChange={(event) => {
                                        table.getColumn(column.name)?.setFilterValue(event.target.value)
                                    }}
                                    className="h-8 w-[150px] lg:w-[200px] mr-2 mb-2"
                                    style={column?.filterStyle}
                                    key={column.name + '-filter-' + index}
                                />
                            )
                        })
                    }
                </div>
                <div className="flex flex-row flex-wrap items-center text-left space-x-2">
                    {
                        columnsTypeFilter &&
                        columnsTypeFilter.map((column: GenericObject, index: number) => {
                            return (
                                <DataTableFacetedFilter
                                    settings={columnsCfg?.columns?.filter((col: GenericObject) => col.name === column.name)[0]}
                                    column={table.getColumn(column.name)}
                                    title={tableDictionary?.columns?.[column.name]?.label ?? column.label}
                                    clearFiltersLabel={tableDictionary?.filters?.clear}
                                    options={column.options.map((option: GenericObject) => {
                                        option.label = tableDictionary?.columns?.[column.name]?.options?.[option.name]?.label ?? option.label;
                                        return option;
                                    })}
                                    key={column.name + '-' + index}
                                />
                            )
                        })
                    }
                    {
                        columnsDropdownFilter &&
                        columnsDropdownFilter.map((column: GenericObject, index: number) => {
                            return (
                                <DataTableDropdownFilter
                                    settings={columnsCfg?.columns?.filter((col: GenericObject) => col.name === column.name)[0]}
                                    column={table.getColumn(column.name)}
                                    title={tableDictionary?.columns?.[column.name]?.label ?? column.label}
                                    clearFiltersLabel={tableDictionary?.filters?.clear}
                                    options={column.options.map((option: GenericObject) => {
                                        option.label = tableDictionary?.columns?.[column.name]?.options?.[option.name]?.label ?? option.label;
                                        return option;
                                    })}
                                    key={column.name + '-' + index}
                                    columnsCfg={columnsCfg}
                                />
                            )
                        })
                    }
                    {((isFiltered) || (columnsCfg?.filtersResetShowCondition ? columnsCfg?.filtersResetShowCondition() : false)) && (
                        <Button
                            variant="ghost"
                            onClick={() => {
                                table.resetColumnFilters();
                                table.resetSorting();
                                if (columnsCfg?.onFiltersReset) {
                                    columnsCfg?.onFiltersReset();
                                }
                            }}
                            className="h-8 px-2 lg:px-3 h-full"
                        >
                            <X className="mr-[0.20rem] h-4 w-4 font-bold" />
                            {/* tableDictionary?.filters?.reset ??  */'Reset'}
                        </Button>
                    )}
                </div>
            </div>
            {
                (view === 'table') &&
                <DataTableViewOptions table={table} tableDictionary={tableDictionary} />
            }
        </div>
    )
}
