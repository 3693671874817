import * as React from "react"
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons"
import { Column } from "@tanstack/react-table"

import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { Separator } from "@/components/ui/separator"
import { FilterIcon } from "lucide-react"

interface DataTableFacetedFilterProps<TData, TValue> {
    settings?: any,
    column?: Column<TData, TValue>
    title?: string
    clearFiltersLabel?: string
    options: {
        name: string
        label: string
        icon?: React.ComponentType<{ className?: string }>
    }[],
    columnsCfg?: any
}

export function DataTableDropdownFilter<TData, TValue>({
    settings,
    column,
    title,
    clearFiltersLabel,
    options,
    columnsCfg,
}: DataTableFacetedFilterProps<TData, TValue>) {
    return (
        <Select
            defaultValue={settings?.getSortingStatus?.()?.value ?? 'none'}
            onValueChange={((value: any) => {
                const directionInfo = columnsCfg?.columns?.filter((col: any) => col.name === column?.id)[0]?.options?.filter((opt: any) => opt.name === value)[0];
                columnsCfg?.columns?.filter((col: any) => col.name === column?.id)[0].setSortingStatus({ name: directionInfo?.sortingName, value: value });
                if (value === 'none') {
                    column?.clearSorting();
                    settings?.onClick(null, null);
                    columnsCfg?.columns?.filter((col: any) => col.name === column?.id)[0].setSortingStatus({ name: null, value: null });
                    return;
                }
                column?.toggleSorting(directionInfo?.direction == 'desc' ? true : false);
                if (settings?.onClick) {
                    settings?.onClick(directionInfo?.direction, directionInfo?.sortingName);
                }
            })}
        >
            <SelectTrigger className="w-auto">
                <SelectValue placeholder={title} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {

                        <SelectItem
                            value="none"
                        >
                            {title}
                        </SelectItem>
                    }
                    {
                        options.map((option, index: number) => (
                            <SelectItem
                                key={option.name + "-" + index}
                                value={option.name}
                            >
                                {option.icon && (
                                    <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                                )}
                                <span>{option.label}</span>
                            </SelectItem>
                        ))

                    }
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}
