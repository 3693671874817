"use client"

import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu"
import { MixerHorizontalIcon } from "@radix-ui/react-icons"
import { Table } from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu"
import { useEffect } from "react"

interface DataTableViewOptionsProps<TData> {
    table: Table<TData>,
    tableDictionary?: any
}

export function DataTableViewOptions<TData>({
    table,
    tableDictionary
}: DataTableViewOptionsProps<TData>) {
    useEffect(() => {
        const hiddenColumns: any[] = JSON.parse(localStorage.getItem('hidden-columns') ?? '[]');
        const columns = table.getAllColumns();
        let filteredColumns: any[] = [];
        hiddenColumns.forEach((hCol: any) => {
            const tempCol = columns.filter((col: any) => ((col.id === hCol.column.id)));
            tempCol[0].toggleVisibility(false);
        });
    }, []);
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="outline"
                    size="sm"
                    className="ml-auto hidden h-9 lg:flex mb-[0.5rem]"
                >
                    <MixerHorizontalIcon className="mr-2 h-4 w-4" />
                    {tableDictionary?.filters?.view ?? "View"}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-min-[150px]">
                <DropdownMenuLabel>{tableDictionary?.filters?.toggleColumns ?? 'Toggle columns'}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {table
                    .getAllColumns()
                    .filter(
                        (column: any) =>
                            typeof column.accessorFn !== "undefined" && column.getCanHide()
                    )
                    .map((column: any) => {
                        return (
                            <DropdownMenuCheckboxItem
                                key={column.id}
                                className="capitalize"
                                checked={column.getIsVisible()}
                                onCheckedChange={(value) => {
                                    column.toggleVisibility(!!value);
                                    let hiddenColumns: any[] = JSON.parse(localStorage.getItem('hidden-columns') ?? '[]');
                                    if (!value) {
                                        hiddenColumns.push({ column });
                                    } else {
                                        hiddenColumns = hiddenColumns.filter((col: any) => (col.column.id !== column.id));
                                    }
                                    localStorage.setItem('hidden-columns', JSON.stringify(hiddenColumns));
                                }}
                            >
                                {tableDictionary?.columns?.[column.id]?.label ?? column.id}
                            </DropdownMenuCheckboxItem>
                        )
                    })}
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
