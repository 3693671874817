"use client"

import * as React from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle
} from "@/components/ui/sheet"
import { ChevronLeft, ChevronRight, TrendingUp } from "lucide-react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import ProductImagesManager from "@/components/standalone/product-images-manager/ProductImagesManager"

export let dataTableSheetModel = { open: false, setOpen: (value: boolean) => { } };

const getSidePreviewTemplate = (row: any, previewCfg: any, tableDictionary: any) => {
    if (!row?.original) return <div>no data!</div>;
    return (
        <div className="h-full flex flex-col flex-1 overflow-hidden">
            <div className="w-100">
                {previewCfg?.main(row, tableDictionary)}
            </div>
            <Tabs defaultValue="gallery" className="flex flex-col flex-1 overflow-hidden">
                <TabsList className="grid w-full grid-cols-3 flex flex-row">
                    {
                        previewCfg?.tabs?.map((tab: any, index: number) => {
                            return (
                                <TabsTrigger key={tab.name + ' ' + index} className="flex-1" value={tab.name}>{tableDictionary?.sheet?.tabs?.[tab.name] ?? tab.label}</TabsTrigger>
                            )
                        })
                    }
                </TabsList>
                <div className="overflow-hidden h-full w-full">
                    {
                        previewCfg?.tabs?.map((tab: any, index: number) => {
                            return (
                                <TabsContent key={tab.name + ' ' + index} className="mt-0 h-full" value={tab.name}>
                                    {tab.template(row, tableDictionary)}
                                </TabsContent>
                            )
                        })
                    }
                </div>
            </Tabs>
        </div>
    );
}

export function DataTableSheet({ ...props }: any) {
    const [open, setOpen] = React.useState(false)
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        dataTableSheetModel.open = open;
        dataTableSheetModel.setOpen = setOpen;
    }, [])

    const openChange = (state: boolean) => {
        if (state === true) {
            props.onOpenCallback();
        } else {
            props.onCloseCallback();
        }
        setOpen(state);
    }

    const nullifyEvents = (e: any) => {
        e.preventDefault();
    }

    return (
        <Sheet defaultOpen={false} open={open} onOpenChange={openChange} modal={false}>
            <SheetContent side={props.side ?? 'top'} className={"p-0" + " " + ((props.side === 'right') ? "sm:max-w-[60%] w-[60%]" : "sm:max-w-full w-full h-full") + " " + ((expanded) ? 'sm:max-w-full w-full h-full' : '')} onPointerDownOutside={nullifyEvents} onInteractOutside={nullifyEvents}>
                <div className="flex flex-col flex-1 h-full">
                    <SheetHeader className="flex flex-col space-y-0">
                        <div className="p-4 space-x-2 border-b flex flex-row items-center">
                            {
                                (props.side !== 'top') &&
                                <div>
                                    {
                                        (expanded) ?
                                            <ChevronRight onClick={() => setExpanded(false)} className="cursor-pointer" />
                                            :
                                            <ChevronLeft onClick={() => setExpanded(true)} className="cursor-pointer" />
                                    }
                                </div>
                            }
                            <SheetTitle>{props.title}</SheetTitle>
                        </div>
                        <SheetDescription className="m-0 p-0 hidden">
                            {props.description}
                        </SheetDescription>
                        {
                            props.actions &&
                            <div className="w-full p-4">
                                {props.actions}
                            </div>
                        }
                    </SheetHeader>
                    <div className="grid gap-4 border-t overflow-hidden flex flex-col flex-1">
                        {props.children}
                        {getSidePreviewTemplate(props.data, props.previewCfg, props.tableDictionary)}
                    </div>
                    {/* <SheetFooter>
                        <SheetClose asChild>
                            <Button type="submit">Save changes</Button>
                        </SheetClose>
                    </SheetFooter> */}
                </div>
            </SheetContent>
        </Sheet>
    )
}
