import { Product } from "@/models/ProductModel";
import { getProductById } from "@/services/api/products.service";
import { ArrowBigDownDashIcon, ArrowBigUpDashIcon, LoaderCircleIcon } from "lucide-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function ProductHoverCardTemplate({ ...props }) {
    const router = useRouter();
    const { locale } = router;

    const [replacedProduct, setReplacedProduct] = useState<Product | null>(null);

    useEffect(() => {
        (async () => {
            const product: Product = await getProductById(props.replacedId);
            setReplacedProduct(product);
        })();
    }, []);

    return (
        replacedProduct ? (
            <div className="flex justify-between items-center whitespace-nowrap space-x-4">
                <div>
                    <img className="w-full max-w-[9rem]" src={replacedProduct?.imageLink ?? ''} />
                </div>
                <div className="space-y-1">
                    <div className="text-sm font-normal">
                        <div className="flex flex-1 flex-col justify-between" >
                            <span className="text-xs truncate mb-1 mt-2">Il prodotto:</span>
                            <div className="flex flex-1 overflow-hidden">
                                <span className="text-xs text-muted-foreground truncate">
                                    {props.tableColumnsDictionary?.title?.options?.[props.current?.title]?.label ?? props.current?.title}
                                </span>
                            </div>
                            <a href={`/workspace/product/${props.current.id}`}>
                                <span>{props.current.name}</span>
                            </a>
                            <div className="flex flex-1 overflow-hidden">
                                <span className="text-xs text-muted-foreground truncate">
                                    {props.tableColumnsDictionary?.description?.options?.[props.current?.description]?.label ?? props.current?.description}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="text-sm font-normal">
                        <div className="flex flex-1 flex-col justify-between" >
                            <span className="text-xs truncate mb-1 mt-2">è stato sostituito da uno più recente:</span>
                            <div className="flex flex-1 overflow-hidden">
                                <span className="text-xs text-muted-foreground truncate">
                                    {props.tableColumnsDictionary?.title?.options?.[replacedProduct?.title]?.label ?? replacedProduct?.title}
                                </span>
                            </div>
                            <a href={`/workspace/product/${replacedProduct.id}`}>
                                <span>{replacedProduct.name}</span>
                            </a>
                            <div className="flex flex-1 overflow-hidden">
                                <span className="text-xs text-muted-foreground truncate">
                                    {props.tableColumnsDictionary?.description?.options?.[replacedProduct?.description]?.label ?? replacedProduct?.description}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="!mt-2">
                        {
                            replacedProduct?.prices?.map((price: any, index: number) => {
                                return (
                                    <div key={price.name + '-' + index} className="w-full flex flex-col">
                                        <span className="text-[0.625rem]">
                                            {price.name == 'PUBLIC' && 'PREZZO AL PUBBLICO'}
                                            {price.name == 'PUBLIC_NO_VAT' && 'PREZZO IVA ESCLUSA'}
                                            {price.name == 'PRIVATE' && 'PREZZO ALL\'INGROSSO'}
                                        </span>
                                        <div className="text-lg font-semibold flex flex-row items-center">
                                            <label className="" htmlFor="">
                                                {new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 }).format(price.value)}
                                            </label>
                                            <span className="ml-1 cursor-pointer">
                                                {price.fluctuation && (price.fluctuation > 0) ? <ArrowBigUpDashIcon className="w-6 h-6 stroke-red-400 hover:stroke-secondary-foreground" /> : ''}
                                                {price.fluctuation && (price.fluctuation < 0) ? <ArrowBigDownDashIcon className="w-6 h-6 stroke-green-400 hover:stroke-secondary-foreground" /> : ''}
                                            </span>
                                        </div>
                                    </div>)
                            })
                        }
                    </div>
                </div>
            </div>
        )
            :
            (
                <LoaderCircleIcon size={22} className={'stroke-2 animate-spin stroke-yellow-500'} />
            )
    )
}
