import { Separator } from "@/components/ui/separator";
import { Product, productSchema } from "@/models/ProductModel";
import { getProductById } from "@/services/api/products.service";
import { ArrowBigDownDashIcon, ArrowBigUpDashIcon, LoaderCircleIcon } from "lucide-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function ProductHoverCardListTemplate({ ...props }) {
    const router = useRouter();
    const { locale } = router;

    const [title, setTitle] = useState<string>(props.title ?? '');
    const [products, setProducts] = useState<Product[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        /* const productList: any = [];
        props.ids.forEach(async (id: string) => {
            const product: any = await getProductById(id);
            productList.push(product);
            setProducts(productList);
        }); */
        (async () => {
            setIsLoading(true);
            const result = await props.fetchFn();
            let productList: any[] = result ?? [];
            const kitList: any[] = productList?.filter((item: any) => item.articleType === "KIT");
            const currentTitle = (kitList?.length) ? 'Componente presente nei seguenti kit:' : 'kit composto dai seguenti componenti:';
            setTitle(currentTitle);
            setProducts(productList);
            setIsLoading(false);
        })();
    }, []);

    return (
        (products && (products?.length > 0) && !isLoading) ? (
            <div className="flex flex-col">
                <h2>{title ?? ''}</h2>
                <Separator className="my-3"></Separator>
                {/* <div className="flex items-center whitespace-nowrap space-x-4">
                    <div>
                        <img className="w-full max-w-[5rem]" src={props.current?.imageLink ?? ''} />
                    </div>
                    <div className="space-y-1">
                        <div className="text-sm font-normal">
                            <div className="flex flex-1 flex-col justify-between" >
                                <div className="flex flex-1 overflow-hidden">
                                    <span className="text-xs text-muted-foreground truncate">
                                        {props.tableColumnsDictionary?.title?.options?.[props.current?.title]?.label ?? props.current?.title}
                                    </span>
                                </div>
                                <a href={`/workspace/product/${props.current.id}`}>
                                    <span>{props.current.name}</span>
                                </a>
                                <div className="flex flex-1 overflow-hidden">
                                    <span className="text-xs text-muted-foreground truncate">
                                        {props.tableColumnsDictionary?.description?.options?.[props.current?.description]?.label ?? props.current?.description}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {
                    products.map((product: Product, index: number) => {
                        return (
                            <div className="flex items-center whitespace-nowrap space-x-4 mt-3" key={product.name + '-' + index}>
                                {/* <Separator className="my-3"></Separator> */}
                                <div>
                                    <img className="w-full max-w-[5rem]" src={product?.imageLink ?? ''} />
                                </div>
                                <div className="space-y-1">
                                    <div className="text-sm font-normal">
                                        <div className="flex flex-1 flex-col justify-between" >
                                            <div className="flex flex-1 overflow-hidden">
                                                <span className="text-xs text-muted-foreground truncate">
                                                    {props.tableColumnsDictionary?.title?.options?.[product?.title]?.label ?? product?.title}
                                                </span>
                                            </div>
                                            <a href={`/workspace/product/${product.id}`}>
                                                <span>{product.name}</span>
                                            </a>
                                            <div className="flex flex-1 overflow-hidden">
                                                <span className="text-xs text-muted-foreground truncate">
                                                    {props.tableColumnsDictionary?.description?.options?.[product?.description]?.label ?? product?.description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        )
            :
            (
                isLoading ?
                    <LoaderCircleIcon size={22} className={'stroke-2 animate-spin stroke-yellow-500'} />
                    :
                    <div>Niente da mostrare</div>
            )
    )
}
