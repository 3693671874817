"use client"

import Link from "next/link"
import { LucideIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import { buttonVariants } from "../ui/button"
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip"
import { usePathname } from 'next/navigation';
import { GenericObject } from "@/models/UtilsModel"
import { useRouter } from "next/router"
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger
} from "../ui/accordion"
import { AccordionHeader } from "@radix-ui/react-accordion"
import { use, useEffect } from "react"
import { menuToggle } from "../layouts/main-layout/MainLayout"
import { Separator } from "../ui/separator"
import FilterAccordion from "./FilterAccordion"

interface NavProps {
    isCollapsed: boolean
    links: {
        name: string
        label?: string
        icon: LucideIcon
        variant: "default" | "ghost"
        href?: string,
        hrefPattern?: string,
        click?: () => void
    }[],
    dictionary?: GenericObject
}

export function Nav({ links, isCollapsed, dictionary }: NavProps) {
    const pathname = usePathname();
    const router = useRouter();

    const routeHandler = (link: any) => {
        const urlTree = router.query;
        const urlWithoutParams = urlTree;
        const linkHref = link.href;
        const url = router.pathname;

        const values = [];
        const regex = /\[(\w+)\]/g;
        let match;

        while ((match = regex.exec(url)) !== null) {
            values.push(match[1]);
        }
        values.forEach((value) => {
            urlWithoutParams[value] = urlTree[value];
        });
        link?.click ? link?.click() : (() => { });
    };

    useEffect(() => {
        if (links.length > 0 && links[0] !== undefined) {
            menuToggle('expand');
        } else {
            menuToggle('collapse');
        }
    }, [links]);

    const accordionTemplate = (itemList: any, level: number = 1) => {
        return itemList?.map((link: any, index: number) => {
            return (link?.children && link?.children?.length) ?
                <Accordion
                    type="single"
                    className={"w-full h-full flex flex-col flex-1 overflow-hidden" + ' ' + ('level-' + level)}
                    collapsible={link.collapsible}
                    value={pathname ?? link.href}
                    defaultValue={pathname ?? link.href}
                    key={link.name + '-' + index}
                >
                    <AccordionItem
                        value={pathname ?? link.href}
                        className={cn(
                            "border-b-0 h-full flex flex-col flex-1"
                        )}
                    >
                        <AccordionHeader
                            className={cn(
                                (link.active) &&
                                "text-primary-foreground pr-2",
                                (level == 1) ? "bg-primary hover:bg-primary/70" : "bg-accent hover:bg-accent/90 dark:text-white",
                            )}
                            asChild
                        >
                            <AccordionTrigger className="p-0 cursor-pointer pr-2 !rounded-none overflow-hidden">
                                <Tooltip delayDuration={0}>
                                    <TooltipTrigger asChild>
                                        <Link
                                            href={link.href || "#"}
                                            className={cn(
                                                buttonVariants({ variant: ((router.asPath == link?.href) || (router.pathname === link.hrefPattern) || link.active) ? 'default' : 'ghost', size: "sm" }),
                                                (link.variant === "default") &&
                                                "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white dark:text-white",
                                                "justify-start",
                                                "flex flex-row content-between w-full cursor-pointer truncate",
                                                "bg-transparent hover:bg-transparent",
                                                (link.active && level != 1) &&
                                                "dark:text-white"
                                            )}
                                        >
                                            {(dictionary?.navigationMenu[link.name] ?? link.label) && (
                                                <label
                                                    className={
                                                        cn("cursor-pointer truncate")
                                                    }
                                                >
                                                    {dictionary?.navigationMenu[link.name] ?? link.label}
                                                </label>
                                            )}
                                        </Link>
                                    </TooltipTrigger>
                                    <TooltipContent side="right" className="flex items-center gap-4">
                                        {(dictionary?.navigationMenu[link.name] ?? link.label) && (
                                            <label className="">
                                                {dictionary?.navigationMenu[link.name] ?? link.label}
                                            </label>
                                        )}
                                    </TooltipContent>
                                </Tooltip>

                            </AccordionTrigger>
                        </AccordionHeader>
                        <AccordionContent className="flex flex-col flex-1 !overflow-auto pb-0" asChild>
                            {
                                (link.children && link.children.length) &&
                                accordionTemplate(link.children, ++level)
                            }
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
                :
                <Tooltip delayDuration={0} key={link?.name + '-' + index}>
                    <TooltipTrigger asChild>
                        <Link
                            key={index}
                            href={link?.href || "#"}
                            onClick={
                                (() => {
                                    routeHandler(link);
                                })
                            }
                            className={cn(
                                buttonVariants({ variant: ((router.asPath == link?.href) || (router.pathname === link?.hrefPattern) || link?.active) ? 'default' : 'ghost', size: "sm" }),
                                (link?.variant === "default") &&
                                "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                (((router.asPath == link?.href) || (router.pathname === link?.hrefPattern) || link?.active)) &&
                                "bg-accent/70 hover:bg-accent/60 ",
                                "justify-start py-4 rounded-none bg-accent/70 text-white hover:bg-white hover:text-black dark:bg-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
                            )}
                        >
                            {(dictionary?.navigationMenu?.[link?.name] ?? link?.label) && (
                                <span
                                    className={cn(
                                        "truncate",
                                        (link.variant === "default") &&
                                        "label-background dark:label-white"
                                    )}
                                >
                                    {dictionary?.navigationMenu?.[link.name] ?? link.label}
                                </span>
                            )}
                        </Link>
                    </TooltipTrigger>
                    <TooltipContent side="right" className="flex items-center gap-4">
                        {(dictionary?.navigationMenu[link?.name] ?? link?.label) && (
                            <label className="">
                                {dictionary?.navigationMenu?.[link.name] ?? link.label}
                            </label>
                        )}
                    </TooltipContent>
                </Tooltip>
        })
    }

    return (
        <div
            data-collapsed={isCollapsed}
            className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2 h-full flex flex-col flex-1 overflow-hidden"
        >
            <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2 h-full flex flex-col flex-1 overflow-hidden content-start">
                {
                    isCollapsed &&
                    links.map((link: any, index) =>
                    (<Tooltip key={index} delayDuration={0}>
                        <TooltipTrigger asChild>
                            <Link
                                href={link?.href || "#"}
                                onClick={() => routeHandler(link)}
                                className={cn(
                                    buttonVariants({ variant: (router.asPath == link?.href) ? 'default' : 'ghost', size: "icon" }),
                                    "h-9 w-9",
                                    link?.variant === "default" &&
                                    "dark:bg-muted dark:label-muted-foreground dark:hover:bg-muted dark:hover:label-white"
                                )}
                            >

                                {
                                    link?.icon &&
                                    <link.icon className="h-4 w-4" />
                                }
                                <span className="sr-only">{dictionary?.navigationMenu[link?.name] ?? link?.label}</span>
                            </Link>
                        </TooltipTrigger>
                        <TooltipContent side="right" className="flex items-center gap-4">
                            {(dictionary?.navigationMenu[link?.name] ?? link?.label) && (
                                <span className="label-muted-foreground">
                                    {dictionary?.navigationMenu[link?.name] ?? link?.label}
                                </span>
                            )}
                        </TooltipContent>
                    </Tooltip>)
                    )
                }

                {
                    (!isCollapsed) &&
                    accordionTemplate(links)
                }

                <Separator className="mb-0" />

                <FilterAccordion

                ></FilterAccordion>
            </nav>
        </div>
    )
}
