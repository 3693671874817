import React, { useRef, useState } from 'react';
import './ImageMagnifier.styles.scss';

const ImageMagnifier = ({ children, magnifiedSrc, showMagnifier, magnifierX, magnifierY, magnifierZoom, zoomFrame }: any) => {
    const magnifierRef: any = useRef(null);

    const magnifierRect = magnifierRef.current?.getBoundingClientRect() ?? { width: 0, height: 0 };

    const x = - (((zoomFrame.left) * ((magnifierRect.width / zoomFrame.width) / 2)));
    const y = - (((zoomFrame.top) * ((magnifierRect.height / zoomFrame.height) / 2)));

    return (
        <div className="image-magnifier">
            {children}
            {showMagnifier && (
                <div
                    className="magnifier"
                    ref={magnifierRef}
                >
                    <img
                        src={magnifiedSrc}
                        alt="Magnified view"
                        style={{
                            transform: `scale(${magnifierZoom.scaleX}, ${magnifierZoom.scaleX}) translate(${x}px, ${y}px)`,
                            transformOrigin: 'left top',
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ImageMagnifier;
