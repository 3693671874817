import React, { MouseEventHandler, useRef, useState } from 'react';
import './ProductImagesManager.styles.scss';
import Carousel from './presentational-components/carousel/Carousel';
import ImageMagnifier from './presentational-components/image-magifier/ImageMagnifier';

const sampleItems: CarouselItem[] = [
    {
        id: '0',
        image: 'https://i.postimg.cc/RhYnBf5m/er-slider.jpg',
        isSelected: true
    }, {
        id: '1',
        image: 'https://i.postimg.cc/qBGQNc37/ro-slider.jpg',
        isSelected: false
    }, {
        id: '2',
        image: 'https://i.postimg.cc/cHdMJQKG/svb-slider.jpg',
        isSelected: false
    }, {
        id: '3',
        image: 'https://i.postimg.cc/C12h7nZn/ms-1.jpg',
        isSelected: false
    }, {
        id: '4',
        image: 'https://i.postimg.cc/NfzMDVHP/willie-mosconi-slider.jpg',
        isSelected: false
    }, {
        id: '5',
        image: 'https://i.postimg.cc/qBGQNc37/ro-slider.jpg',
        isSelected: false
    }
];

function ProductImagesManager({ ...props }) {

    const [selectedcarouselImage, setSelectedCarouselImage] = useState('');
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [magnifierX, setMagnifierX] = useState(0);
    const [magnifierY, setMagnifierY] = useState(0);
    const [magnifierZoom, setMagnifierZoom] = useState({ scaleX: 0, scaleY: 0 });

    const [zoomFrame, setZoomFrame] = useState({
        display: 'none',
        width: 0,
        height: 0,
        left: 0,
        top: 0,
    });

    const imageMagnifierRef = useRef<HTMLImageElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    function carouselNotifySelection(selection: { image: string }) {
        setSelectedCarouselImage(selection.image);
    }

    const handleMouseEnter = () => setShowMagnifier(true);
    const handleMouseLeave = () => {
        setShowMagnifier(false);
        setZoomFrame({ ...zoomFrame, display: 'none' })
    };
    const handleMouseMove: MouseEventHandler<HTMLImageElement> = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        const image = event.currentTarget;
        const imageRect = image.getBoundingClientRect();

        const magnifierRect: DOMRect | undefined = imageMagnifierRef?.current?.getBoundingClientRect();
        const zoomFrameWidth = imageRect.width / 2;
        const zoomFrameHeight = zoomFrameWidth * ((magnifierRect?.height ?? 1) / (magnifierRect?.width ?? 1));
        const zoomFrameX = (event.clientX - imageRect.left) - (zoomFrameWidth / 2);
        const zoomFrameY = (event.clientY - imageRect.top) - (zoomFrameHeight / 2);
        const magnifierTransform = { scaleX: (imageRect.width / zoomFrameWidth), scaleY: (imageRect.height / zoomFrameHeight) };

        setZoomFrame({
            display: 'block',
            width: zoomFrameWidth,
            height: zoomFrameHeight,
            left: ((zoomFrameX < 0)) ? 0 : ((zoomFrameX > zoomFrameWidth)) ? zoomFrameWidth : zoomFrameX,
            top: ((zoomFrameY < 0)) ? 0 : ((zoomFrameY > (imageRect.height - zoomFrameHeight))) ? (imageRect.height - zoomFrameHeight) : zoomFrameY,
        });

        const x = - (((zoomFrame.left - (zoomFrame.width / 2))));
        const y = - (((zoomFrame.top - (zoomFrame.height / 2))));

        setMagnifierX(x);
        setMagnifierY(y);
        setMagnifierZoom({ scaleX: magnifierTransform.scaleX, scaleY: magnifierTransform.scaleY });
    };

    return (
        <div id='product-images-manager' className="">
            <div className="left-panel">
                <div className='image-frame'>
                    <div className='large-image'>
                        <img
                            ref={imageRef}
                            src={selectedcarouselImage}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onMouseMove={handleMouseMove}
                            style={{ objectFit: 'contain' }}
                        ></img>
                        <div className='zoom-frame' style={zoomFrame}></div>
                    </div>
                </div>
                <Carousel notifySelection={carouselNotifySelection} items={sampleItems}></Carousel>
            </div>
            <div className="right-panel">
                <div className='image-magnifier-wrapper' ref={imageMagnifierRef}>
                    <ImageMagnifier
                        magnifiedSrc={selectedcarouselImage}
                        showMagnifier={showMagnifier}
                        magnifierX={magnifierX}
                        magnifierY={magnifierY}
                        magnifierZoom={magnifierZoom}
                        zoomFrame={zoomFrame}
                        imageRef={imageRef}
                    >
                        <div className='flex w-full h-full justify-center items-center border-2'>
                            {props.children}
                        </div>
                    </ImageMagnifier>
                </div>
            </div>
        </div>
    )
}

export default ProductImagesManager;
