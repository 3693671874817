import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { GenericObject } from "@/models/UtilsModel";
import { Column, ColumnDef } from "@tanstack/react-table";
import { CheckIcon, CircleCheckIcon, LoaderCircleIcon } from "lucide-react";
import { DataTableRowActions } from "./data-table-row-actions";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { cn } from "@/lib/utils";

let tableColumnsDictionary: GenericObject | null = null;

export function DataCards({ ...props }) {

    const data = props.table.getRowModel().rows;
    const cardColumns = getCardColumns(props.cardCfg, props.tableDictionary?.columns);
    const checkboxTemplate: any = (cardColumns[0]?.id === 'select') ? cardColumns[0] : { cell: () => null };
    const templateList: any = (cardColumns[0]?.id === 'select') ? cardColumns.slice(1) : cardColumns;

    return (
        <div className="w-full h-full flex flex-col">
            {/* <div className="px-4 py-2 flex border">
                {
                    (checkboxTemplate) &&
                    <div className="flex flex-row items-center">
                        <Checkbox
                            id="massive-selection"
                            checked={
                                props.table?.getIsAllPageRowsSelected() ||
                                (props.table?.getIsSomePageRowsSelected() && "indeterminate")
                            }
                            onCheckedChange={(value: any) => props.table?.toggleAllPageRowsSelected(!!value)}
                            aria-label="Select all"
                            className="data-[state=checked]:bg-primary"
                        />
                        <label htmlFor="massive-selection" className="ml-2 cursor-pointer">Select all checkbox</label>
                    </div>
                }
            </div> */}
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-4 overflow-auto p-4 w-full flex-grow">
                {data.map((item: any, index: number) => (
                    <Card
                        key={index}
                        className={"shadow-lg cursor-pointer" + " " + (item?.original?.id === props.itemSelection?.original?.id ? 'active-row' : '')}
                        onClick={() => props.itemSelectionFn(item)}
                    >
                        <CardHeader className="px-6 py-4 pt-6 border-b mb-3">
                            <CardTitle className="text-base flex flex-row items-center justify-between">
                                {
                                    (checkboxTemplate && checkboxTemplate.cell) &&
                                    checkboxTemplate.cell({ row: item })
                                }
                                <label className="ml-2 text-l">{tableColumnsDictionary?.name?.options?.[item.original.name]?.label ?? item?.original?.name}</label>
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            {
                                item.original.description &&
                                <p>{item.original.description}</p>
                            }
                            {
                                templateList?.map((columnTemplate: any, index: number) => {
                                    return (
                                        <div
                                            key={'card-row-' + index}
                                            className="flex flex-row items-center py-2 space-x-2 justify-between"
                                        >
                                            {columnTemplate.header({ column: columnTemplate })}
                                            {columnTemplate.cell({ row: item })}
                                        </div>
                                    );
                                })
                            }
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    );
};

const getCardColumns = (columnsCfg: any, dictionary: GenericObject | null = null) => {
    tableColumnsDictionary = dictionary;
    let columns: ColumnDef<any>[] = [];
    if (columnsCfg.checkboxRow && (columnsCfg?.massiveActions?.length > 0)) {
        const columnTemplate = getColumnTemplate({ type: 'checkboxRow' });
        columnTemplate ? columns.push(columnTemplate) : null
    }

    if (columnsCfg.columns) {
        columnsCfg.columns.forEach((column: GenericObject) => {
            const columnTemplate = getColumnTemplate(column);
            columnTemplate ? columns.push(columnTemplate) : null;
        })
    }

    return columns;
}

const getColumnTemplate: (columnItem: any) => ColumnDef<any> | null = (columnItem: any) => {
    switch (columnItem.type) {
        case 'checkboxRow':
            return getCheckboxRowTemplate(columnItem);
        case 'base':
            return getBaseTemplate(columnItem);
        case 'filter':
            return getFilterTemplate(columnItem);
        case 'action':
            return getActionTemplate(columnItem);
        default:
            return null;
    }
}

const getCheckboxRowTemplate = (columnItem: any) => {
    return {
        id: "select",
        cell: ({ row }: any) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value: any) => { row.toggleSelected(!!value) }}
                aria-label="Select row"
                className="data-[state=checked]:bg-primary border-primary"
                onClick={(e: any) => { e.stopPropagation() }}
            />
        ),
        enableSorting: false,
        enableHiding: false,
    }
}

const getBaseTemplate = (columnItem: any) => {
    return {
        accessorKey: columnItem.name,
        header: ({ column }: any) => (
            <h2 className="text-sm">{tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label}</h2>
        ),
        cell: ({ row }: any) => {
            const label = tableColumnsDictionary?.[columnItem.name]?.options?.[row.getValue(columnItem.name)]?.label ?? row.getValue(columnItem.name);

            return (
                <div className="flex items-center space-x-2">
                    {
                        (!!columnItem?.icons?.length) &&
                        columnItem.icons.map((iconItem: any, index: number) => {
                            if ((iconItem.type == 'button') || !iconItem.type) {
                                return (
                                    iconItem?.onClick ?
                                        <Tooltip key={iconItem.name + '-' + index} delayDuration={0} disableHoverableContent={false}>
                                            <TooltipTrigger>
                                                <Badge
                                                    variant="outline"
                                                    key={iconItem.name + '-' + index}
                                                    className={"cursor-pointer block"}
                                                    onClick={async (e) => {
                                                        e.stopPropagation();
                                                        await iconItem.onClick(row.original, iconItem.name);
                                                    }}
                                                >
                                                    {
                                                        ((row.original?.status?.state === 'pending') && (iconItem.name === row.original?.status?.name)) &&
                                                        <LoaderCircleIcon name={iconItem.name} size={iconItem.size ?? 18} className={'stroke-2 animate-spin stroke-yellow-500'} />
                                                    }
                                                    {
                                                        ((row.original?.status?.state === 'success') && (iconItem.name === row.original?.status?.name)) &&
                                                        <CircleCheckIcon name={iconItem.name} size={iconItem.size ?? 18} className={'stroke-2 fill-green-600 stroke-secondary scale-125'} />
                                                    }
                                                    {
                                                        ((!row.original?.status?.state) || !(iconItem.name === row.original?.status?.name)) &&
                                                        <iconItem.icon name={iconItem.name} size={iconItem.size ?? 18} className={iconItem.className + ' ' + ((row.original[iconItem.classNameConditionKey]) && iconItem.classNameActive)} />
                                                    }
                                                </Badge>
                                            </TooltipTrigger>
                                            <TooltipContent side="bottom" className={"flex items-center gap-4" + " " + (tableColumnsDictionary?.['actions']?.options?.[iconItem.name]?.tooltip) ? '' : 'display-none'}>
                                                {
                                                    (() => {
                                                        const tooltip = tableColumnsDictionary?.['actions']?.options?.[iconItem.name]?.tooltip ?? '';
                                                        const disabledTooltip = tableColumnsDictionary?.['actions']?.options?.[iconItem.name]?.disabledTooltip ?? '';
                                                        return !(row.original[iconItem.classNameConditionKey]) ? tooltip : disabledTooltip;
                                                    })()
                                                }
                                            </TooltipContent>
                                        </Tooltip>
                                        :
                                        <iconItem.icon key={iconItem.name + '-' + index} name={iconItem.name} size={iconItem.size ?? 18} className={'mr-1 ' + iconItem.className + ' ' + ((row.original[iconItem.classNameConditionKey]) && iconItem.classNameActive)} />
                                )
                            }
                            if ((iconItem.type == 'combobox')) {
                                return getColumnBadge(row, iconItem, index)
                            }
                        })
                    }
                    <span className="max-w-full truncate font-medium bold">
                        {label}
                    </span>
                </div>
            )
        },
        enableSorting: columnItem.enableSorting ?? false,
        enableHiding: columnItem.enableHiding ?? false,
        filterFn: (row: any, id: any, value: any) => {
            const rowValue = tableColumnsDictionary?.[columnItem.name]?.options?.[row.getValue(columnItem.name)]?.label ?? row.getValue(columnItem.name);
            console.log(rowValue.toLowerCase(), (value.toLowerCase()));
            return rowValue.toLowerCase().includes(value.toLowerCase());
        },
    }
}

const getFilterTemplate = (columnItem: any) => {
    return {
        accessorKey: columnItem.name,
        header: ({ column }: any) => (
            <h2 className="text-sm">{tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label}</h2>
        ),
        cell: ({ row }: any) => {
            const option: GenericObject = columnItem.options.find(
                (option: GenericObject) => option.name === row.getValue(columnItem.name)
            )

            if (!option) {
                return null
            }

            return (
                <div className="flex items-center">
                    {option.icon && (
                        <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{tableColumnsDictionary?.[columnItem.name]?.options?.[option.name]?.label ?? option.label}</span>
                </div>
            )
        },
        filterFn: (row: any, id: any, value: any) => {
            return value.includes(row.getValue(id))
        },
        enableSorting: columnItem.enableSorting ?? false,
        enableHiding: columnItem.enableHiding ?? false
    }
}

const getActionTemplate = (columnItem: any) => {
    return {
        id: columnItem.name,
        header: ({ column }: any) => (
            <h2 className="text-sm">{tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label}</h2>
        ),
        cell: ({ row }: any) => {
            return (
                <div className="flex items-center space-x-2">
                    {
                        columnItem.actions.filter((action: any) => !action.subMenu).map((action: any, index: number) => {
                            if ((action.type == 'button') || !action.type) {
                                return (
                                    <Tooltip key={action.name + '-' + index} delayDuration={0}>
                                        <TooltipTrigger>
                                            <Badge variant="outline" key={action.name + '-' + index} className={"cursor-pointer block"} onClick={(e) => { e.stopPropagation(); action.onClick(row.original, action.name) ?? (() => { }) }}>
                                                {
                                                    ((row.original?.status?.state === 'pending') && (action.name === row.original?.status?.name)) &&
                                                    <LoaderCircleIcon name={action.name} size={action.size ?? 18} className={'stroke-2 animate-spin stroke-yellow-500'} />
                                                }
                                                {
                                                    ((row.original?.status?.state === 'success') && (action.name === row.original?.status?.name)) &&
                                                    <CircleCheckIcon name={action.name} size={action.size ?? 18} className={'stroke-2 fill-green-600 stroke-secondary scale-125'} />
                                                }
                                                {
                                                    ((!row.original?.status?.state) || !(action.name === row.original?.status?.name)) &&
                                                    <action.icon name={action.name} size={action.size ?? 18} className={action.className + ' ' + ((row.original[action.classNameConditionKey]) && action.classNameActive)} />
                                                }
                                            </Badge>
                                        </TooltipTrigger>
                                        <TooltipContent side="bottom" className="flex items-center gap-4">
                                            {
                                                (() => {
                                                    const tooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.tooltip ?? '';
                                                    const disabledTooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.disabledTooltip ?? '';
                                                    return !(row.original[action.classNameConditionKey]) ? tooltip : disabledTooltip;
                                                })()
                                            }
                                        </TooltipContent>
                                    </Tooltip>
                                )
                            }
                            if ((action.type == 'combobox')) {
                                return getColumnBadge(row, action, index)
                            }
                        })
                    }
                    <DataTableRowActions row={row} actions={columnItem.actions.filter((action: any) => action.subMenu)} />
                </div>
            )
        }
    }
}

const getColumnBadge = (row: any, action: any, index: number) => {
    return (<DropdownMenu key={action.name + '-' + index}>
        <Tooltip>
            <TooltipTrigger asChild>
                <DropdownMenuTrigger>
                    <Badge variant="outline" key={action.name + '-' + index} className={"cursor-pointer"}>
                        <action.icon name={action.name} size={action.size ?? 18} className={action.className + ' ' + ((row.original[action.classNameConditionKey]) && action.classNameActive)} />
                    </Badge>
                </DropdownMenuTrigger>
            </TooltipTrigger>
            <TooltipContent side="top" className="flex items-center gap-4">
                {action?.tooltip ? action?.tooltip : ''}
            </TooltipContent>
        </Tooltip>
        <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
            <Command>
                <CommandInput placeholder={action?.placeholder ?? "Cerca tra i clienti..."} />
                <CommandList>
                    <CommandEmpty>no data</CommandEmpty>
                    <CommandGroup>
                        {
                            action?.getOptions(row?.original?.id)?.map((option: any, index: number) => (
                                <CommandItem
                                    key={option.name + '-' + index}
                                    value={option.name}
                                    onSelect={(e: any) => {
                                        action.onOptionSelect(row?.original?.id, option.id);
                                        /* e.stopPropagation(); */
                                    }}
                                >
                                    <CheckIcon
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            action?.getCurrentOption(row?.original?.id)?.id === option?.id ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                    {option.name}
                                </CommandItem>
                            ))
                        }
                    </CommandGroup>
                </CommandList>
            </Command>
        </DropdownMenuContent>
    </DropdownMenu>)
}
