'use client'

import React, { useEffect, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import { AccordionHeader } from '@radix-ui/react-accordion';
import { XIcon } from 'lucide-react';
import { getLIPayload, getTableInstance, setDataTable, setDataTableFn, setLIPayload } from '../orchestrators/cool-table/components/data-table';
import { getColumnCfg } from '@/services/api/products.service';

interface Filter {
    id: number;
    label: string;
}

const tempFilterCategories = [
    {
        category: "Colors",
        filters: [
            { id: 1, label: "Red" },
            { id: 2, label: "Blue" },
            { id: 3, label: "Green" },
        ],
    },
    {
        category: "Sizes",
        filters: [
            { id: 4, label: "Small" },
            { id: 5, label: "Medium" },
            { id: 6, label: "Large" },
        ],
    },
    {
        category: "Shapes",
        filters: [
            { id: 7, label: "Circle" },
            { id: 8, label: "Square" },
            { id: 9, label: "Triangle" },
        ],
    },
];

export let getSelectedF: any = () => { };
export let setSelectedF: any = () => { };

export let setSBF: any = () => { };
export let setSGF: any = () => { };
export let setSSF: any = () => { };

export let getSBF: any = () => { };
export let getSGF: any = () => { };
export let getSSF: any = () => { };

export let getAV: any = () => { };
export let setAV: any = () => { };

export let getBF: any = () => { };
export let setBF: any = () => { };

export let getSF: any = () => { };
export let setSF: any = () => { };

export let getGF: any = () => { };
export let setGF: any = () => { };

export let resetAll: any = () => {
    setBF([]);
    setSF([]);
    setGF([]);
    setSelectedF([]);
    setSBF([]);
    setSGF([]);
    setSSF([]);
}

const FilterAccordion = ({ ...props }) => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [selectedBrandsFilters, setSelectedBrandsFilters] = useState<string[]>([]);
    const [selectedGridsFilters, setSelectedGridsFilters] = useState<string[]>([]);
    const [selectedSpecsFilters, setSelectedSpecsFilters] = useState<string[]>([]);
    const [accordionValue, setAccordionValue] = useState('');

    setSelectedF = (data: any) => setSelectedFilters(data);

    setSBF = (data: any) => setSelectedBrandsFilters(data);
    setSGF = (data: any) => setSelectedGridsFilters(data);
    setSSF = (data: any) => setSelectedSpecsFilters(data);

    getSBF = () => selectedBrandsFilters;
    getSGF = () => selectedGridsFilters;
    getSSF = () => selectedSpecsFilters;

    getAV = () => accordionValue;
    setAV = (data: any) => setAccordionValue(data);

    const [brandsFilter, setBrandsFilter] = useState<any[]>([]);
    const [specsFilter, setSpecsFilter] = useState<any[]>([]);
    const [gridsFilter, setGridsFilter] = useState<any[]>([]);

    getBF = () => brandsFilter;
    setBF = (data: any) => setBrandsFilter(data);

    getSF = () => specsFilter;
    setSF = (data: any) => setSpecsFilter(data);

    getGF = () => gridsFilter;
    setGF = (data: any) => setGridsFilter(data);


    const toggleFilter = async (filter: any, category: any, setSF: any) => {
        setSF((prev: any) => {
            const _prev = [...selectedBrandsFilters, ...selectedGridsFilters, ...selectedSpecsFilters];
            let filterList = null;
            let result = null;
            if (_prev.map((p: any) => p.id).includes(filter.id)) {
                filterList = _prev.filter((f: any) => f.id !== filter.id);
                result = prev.filter((f: any) => f.id !== filter.id);
            } else {
                filter.parentId = category?.id;
                filter.parentType = (category?.category === 'Brands') ? 'brands' : ((category?.category === 'Grids') ? 'grids' : 'specs');
                filterList = [..._prev, filter];
                result = [...prev, filter]
            }
            const selectedBrands = filterList.filter((f: any) => f.parentType === 'brands').map((f: any) => f.id);
            const selectedSpecs: any = {};
            filterList.filter((f: any) => f.parentType === 'specs').forEach((f: any) => {
                selectedSpecs[f.parentId] = (selectedSpecs?.[f.parentId]) ? [...selectedSpecs[f.parentId], f.id] : [f.id];
            });
            const selectedGrids = filterList.filter((f: any) => f.parentType === 'grids').map((f: any) => f.id);
            const payloadToIntegrate = {
                brands: selectedBrands.length > 0 ? JSON.stringify(selectedBrands) : null,
                specs: JSON.stringify(selectedSpecs),
                grids: selectedGrids.length > 0 ? JSON.stringify(selectedGrids) : null,
            }

            if (!!getColumnCfg()?.onTableScrollFn) {
                setDataTable({ data: [] });
                setLIPayload({ ...getLIPayload(), ...payloadToIntegrate });
            } else {
                setDataTableFn(payloadToIntegrate);
            }
            return result;
        }
        );
    };

    const toggleSpecsFilter = async (filter: any, category: any, setSF: any) => {
        setSF((prev: any) => {
            const _prev = [...selectedBrandsFilters, ...selectedGridsFilters, ...selectedSpecsFilters];
            let filterList = null;
            let result = null;
            if ((_prev.map((p: any) => p.id).includes(filter.id)) && (_prev.map((p: any) => p.parentId).includes(filter.parentId))) {
                filterList = _prev.filter((f: any) => ((f.id !== filter.id) || (f.parentId !== filter.parentId)));
                result = prev.filter((f: any) => ((f.id !== filter.id) || (f.parentId !== filter.parentId)));
            } else {
                filter.parentId = category?.id;
                filter.parentType = (category?.category === 'Brands') ? 'brands' : ((category?.category === 'Grids') ? 'grids' : 'specs');
                filterList = [..._prev, filter];
                result = [...prev, filter]
            }
            const selectedBrands = filterList.filter((f: any) => f.parentType === 'brands').map((f: any) => f.id);
            const selectedSpecs: any = {};
            filterList.filter((f: any) => f.parentType === 'specs').forEach((f: any) => {
                selectedSpecs[f.parentId] = (selectedSpecs?.[f.parentId]) ? [...selectedSpecs[f.parentId], f.id] : [f.id];
            });
            const selectedGrids = filterList.filter((f: any) => f.parentType === 'grids').map((f: any) => f.id);
            const payloadToIntegrate = {
                brands: selectedBrands.length > 0 ? JSON.stringify(selectedBrands) : null,
                specs: JSON.stringify(selectedSpecs),
                grids: selectedGrids.length > 0 ? JSON.stringify(selectedGrids) : null,
            };
            if (!!getColumnCfg()?.onTableScrollFn) {
                setDataTable({ data: [] });
                setLIPayload({ ...getLIPayload(), ...payloadToIntegrate });
            } else {
                setDataTableFn(payloadToIntegrate);
            }
            return result;
        }
        );
    };

    const isSelected = (filterId: string, sf: any[]) => sf.map(sf => sf?.id).includes(filterId);
    const isSpecsSelected = (filter: any, sf: any[]) => sf.filter(sf => (sf?.parentId === filter?.parentId)).map(sf => sf?.id).includes(filter.id);

    return (brandsFilter || specsFilter || gridsFilter) ? (
        <Accordion type="single" collapsible className={"w-full flex flex-col flex-1 overflow-auto"} value={accordionValue} onValueChange={(val: any) => setAccordionValue(val)}>
            {(brandsFilter && (brandsFilter.length > 0)) ? accordionTemplate(brandsFilter, selectedBrandsFilters, setSelectedBrandsFilters, { type: 'brands' }) : null}
            {(gridsFilter && (gridsFilter.length > 0)) ? accordionTemplate(gridsFilter, selectedGridsFilters, setSelectedGridsFilters, { hideCounters: true }) : null}
            {(specsFilter && (specsFilter.length > 0)) ? accordionSpecsTemplate(specsFilter, selectedSpecsFilters, setSelectedSpecsFilters) : null}
            {
                ((selectedFilters?.length > 0) || (selectedBrandsFilters?.length > 0) || (selectedGridsFilters?.length > 0) || (selectedSpecsFilters?.length > 0)) &&
                <div
                    className={cn(
                        "border-b-0 h-full flex flex-col flex-1"
                    )}
                    onClick={async () => {
                        const payload = {
                            brands: null,
                            grids: null,
                            specs: null
                        };
                        if (!!getColumnCfg()?.onTableScrollFn) {
                            setDataTable({ data: [] })
                            setLIPayload({ ...getLIPayload(), ...payload });
                        } else {
                            await setDataTableFn(payload);
                        }
                        /* setSelectedFilters([]); */
                        setSelectedBrandsFilters([]);
                        setSelectedGridsFilters([]);
                        setSelectedSpecsFilters([]);
                        setAccordionValue('');
                    }}
                >
                    <div
                        className={cn(
                            "AccordionHeader",
                            "bg-[#d9534f]/90 text-white hover:bg-[#d9534f]/100 pr-2 mt-2"
                        )}
                    >
                        <div
                            className="p-0 cursor-pointer pr-2 !rounded-none overflow-hidden"
                        >
                            <label
                                htmlFor=""
                                className={cn(
                                    "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                    "justify-start h-9 px-3 text-sm",
                                    "flex flex-row content-between w-full cursor-pointer truncate items-center justify-center",
                                    "label-background dark:label-white"
                                )}
                            >
                                Tutti i risultati
                            </label>
                        </div>
                    </div>
                </div>
            }
        </Accordion>
    ) : null;

    function accordionTemplate(filterList: any[], selectedF: string[] = selectedFilters, setSF: any, options?: any) {
        return <>
            {
                (filterList ?? []).map((category: any, index) => (
                    <AccordionItem
                        key={category.category + '-' + index}
                        value={category.category}
                        className={cn(
                            "group",
                            "border-b-0 h-full flex flex-col flex-1"
                        )}
                    >
                        <AccordionHeader
                            className={cn(
                                "AccordionHeader",
                                "bg-transparent border-secondary border-2 bg-background text-foreground hover:bg-secondary/90 hover:text-secondary-foreground pr-2 data-[state=open]:bg-secondary data-[state=open]:text-secondary-foreground",
                                (options?.type === 'brands') ? '' : 'mt-2'
                            )}
                            asChild
                        >
                            <AccordionTrigger
                                className="p-0 cursor-pointer pr-2 !rounded-none overflow-hidden"
                            >
                                <label
                                    htmlFor=""
                                    className={cn(
                                        "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                        "justify-start h-9 px-3 text-sm",
                                        "flex flex-row content-between w-full cursor-pointer truncate items-center",
                                        "label-background dark:label-white"
                                    )}
                                >
                                    {category.label}
                                </label>
                            </AccordionTrigger>
                        </AccordionHeader>
                        <div className={cn(
                            "selectedFilters flex flex-wrap gap-2 flex-row border-secondary px-0 group-data-[state=open]:border-0 group-data-[state=open]:p-0",
                            (category?.filters?.filter((f: any) => selectedF?.some((sf: any) => sf.id === f.id)).length > 0) ? 'border-[1px] border-t-0 !px-2' : ''
                        )}>
                            {
                                category?.filters?.filter((f: any) => selectedF?.some((sf: any) => sf.id === f.id)).map((c: any) => {
                                    return (
                                        <Button
                                            key={c.id}
                                            className={cn(
                                                "bg-secondary text-secondary-foreground hover:bg-secondary/70 hover:text-secondary-foreground",
                                                "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                                "justify-start h-auto px-2 py-2 rounded-none text-xs cursor-pointer",
                                                `${isSelected(c.id, selectedF) ? 'bg-secondary text-secondary-foreground' : ''}`
                                            )}
                                        >
                                            {c.label} {(c.count && (category?.filters?.filter((f: any) => selectedF.some((sf: any) => (sf.id === f.id && sf.parentType === 'brands'))).length == 0)) ? ('(' + c.count + ')') : ''}
                                            <XIcon
                                                className="w-4 h-4 ml-1"
                                                onClick={
                                                    () => toggleFilter(c, category, setSF)
                                                }
                                            ></XIcon>
                                        </Button>
                                    )
                                })
                            }
                        </div>
                        <AccordionContent
                            className="flex flex-col flex-1 !overflow-auto py-2 px-2 border-[1px] border-t-0 border-secondary"
                            asChild
                        >
                            <div className="flex flex-wrap gap-2 flex-row">
                                {category?.filters?.map((filter: any) => (
                                    <Button
                                        key={filter.id}
                                        className={cn(
                                            "bg-background text-foreground border-2 border-secondary hover:bg-secondary/70 hover:text-secondary-foreground",
                                            "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                            "justify-start h-auto px-2 py-2 rounded-none text-xs font-bold",
                                            `${isSelected(filter.id, selectedF) ? 'bg-secondary text-secondary-foreground' : ''}`
                                        )}
                                        onClick={() => toggleFilter(filter, category, setSF)}
                                    >
                                        {filter.label} {(filter.count && !options?.hideCounters && (category?.filters?.filter((f: any) => selectedF.some((sf: any) => (sf.id === f.id && sf.parentType === 'brands'))).length == 0) ? ('(' + filter.count + ')') : '')} {(filter.type ? ('(' + filter.type + ')') : '')}
                                    </Button>
                                ))}
                            </div>

                        </AccordionContent>
                    </AccordionItem>
                ))}
        </>
    }

    function accordionSpecsTemplate(filterList: any[], selectedF: string[] = selectedFilters, setSF: any, options?: any) {
        return <>
            {
                (filterList ?? []).map((category: any, index) => (
                    <AccordionItem
                        key={category.category + '-' + index}
                        value={category.category}
                        className={cn(
                            "group",
                            "border-b-0 h-full flex flex-col flex-1"
                        )}
                    >
                        <AccordionHeader
                            className={cn(
                                "AccordionHeader",
                                "bg-transparent border-secondary border-2 bg-background text-foreground hover:bg-secondary/90 hover:text-secondary-foreground pr-2 data-[state=open]:bg-secondary data-[state=open]:text-secondary-foreground",
                                (options?.type === 'brands') ? '' : 'mt-2'
                            )}
                            asChild
                        >
                            <AccordionTrigger
                                className="p-0 cursor-pointer pr-2 !rounded-none overflow-hidden"
                            >
                                <label
                                    htmlFor=""
                                    className={cn(
                                        "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                        "justify-start h-9 px-3 text-sm",
                                        "flex flex-row content-between w-full cursor-pointer truncate items-center",
                                        "label-background dark:label-white"
                                    )}
                                >
                                    {category.label}
                                </label>
                            </AccordionTrigger>
                        </AccordionHeader>
                        <div className={cn(
                            "selectedFilters flex flex-wrap gap-2 flex-row border-secondary px-0 group-data-[state=open]:border-0 group-data-[state=open]:p-0",
                            (category?.filters?.filter((f: any) => selectedF?.some((sf: any) => sf.id === f.id)).length > 0) ? 'border-[1px] border-t-0 !px-2' : ''
                        )}>
                            {
                                category?.filters?.filter((f: any) => selectedF?.some((sf: any) => ((sf.id === f.id) && (sf.parentId === f.parentId)))).map((c: any) => {
                                    return (
                                        <Button
                                            key={c.id}
                                            className={cn(
                                                "bg-secondary text-secondary-foreground hover:bg-secondary/70 hover:text-secondary-foreground",
                                                "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                                "justify-start h-auto px-2 py-2 rounded-none text-xs cursor-pointer",
                                                `${isSpecsSelected(c, selectedF) ? 'bg-secondary text-secondary-foreground' : ''}`
                                            )}
                                        >
                                            {c.label} {(c.count && (category?.filters?.filter((f: any) => selectedF.some((sf: any) => (sf.id === f.id && sf.parentType === 'brands'))).length == 0)) ? ('(' + c.count + ')') : ''}
                                            <XIcon
                                                className="w-4 h-4 ml-1"
                                                onClick={
                                                    () => toggleSpecsFilter(c, category, setSF)
                                                }
                                            ></XIcon>
                                        </Button>
                                    )
                                })
                            }
                        </div>
                        <AccordionContent
                            className="flex flex-col flex-1 !overflow-auto py-2 px-2 border-[1px] border-t-0 border-secondary"
                            asChild
                        >
                            <div className="flex flex-wrap gap-2 flex-row">
                                {category?.filters?.map((filter: any) => (
                                    <Button
                                        key={filter.id}
                                        className={cn(
                                            "bg-background text-foreground border-2 border-secondary hover:bg-secondary/70 hover:text-secondary-foreground",
                                            "dark:bg-muted dark:label-white dark:hover:bg-muted dark:hover:label-white",
                                            "justify-start h-auto px-2 py-2 rounded-none text-xs font-bold",
                                            `${isSpecsSelected(filter, selectedF) ? 'bg-secondary text-secondary-foreground' : ''}`
                                        )}
                                        onClick={() => toggleSpecsFilter(filter, category, setSF)}
                                    >
                                        {filter.label} {(filter.count && !options?.hideCounters && (category?.filters?.filter((f: any) => selectedF.some((sf: any) => (sf.id === f.id && sf.parentType === 'brands'))).length == 0) ? ('(' + filter.count + ')') : '')} {(filter.type ? ('(' + filter.type + ')') : '')}
                                    </Button>
                                ))}
                            </div>

                        </AccordionContent>
                    </AccordionItem>
                ))}
        </>
    }
};

export default FilterAccordion;
