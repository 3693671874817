"use client"

import * as React from "react"
import Link from "next/link"

import { cn } from "@/lib/utils"

import { buttonVariants } from "@/components/ui/button"

import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"

import { Icons } from "@/components/Icons"
import { TranslationsContext } from "@/components/providers/TranslationsProvider"
import { LinkProps, NavigationItemsProps } from "@/models/NavigationModel"
import { GenericObject } from "@/models/UtilsModel"
import { useRouter } from "next/router"
import { usePathname } from "next/navigation"

let router: any = null;
let pathName: any = null;
let navigate: any = null;

const routeHandler = (link: any) => {
    const urlTree = router.query;
    const urlWithoutParams = urlTree;
    const url = router.pathname;
    const values = [];
    const regex = /\[(\w+)\]/g;
    let match;

    while ((match = regex.exec(url)) !== null) {
        values.push(match[1]);
    }
    values.forEach((value, index) => {
        urlWithoutParams[value] = urlTree[value];
    });
    link?.click ? link?.click() : (() => { });
};

function getSimpleStructure(item: NavigationItemsProps, i: number, dictionary: GenericObject) {
    return (
        <NavigationMenuItem key={item.name + '-' + item.href + '-' + i}>
            <NavigationMenuTrigger>{dictionary?.navigationMenu[item.name] ?? item.label}</NavigationMenuTrigger>
            <NavigationMenuContent>
                <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
                    {item.children?.map((child: LinkProps) => (
                        <ListItem
                            key={child.name}
                            title={dictionary?.navigationMenu[child.name] ?? child.label}
                            href={child.href}
                        >
                            {child.description}
                        </ListItem>
                    ))}
                </ul>
            </NavigationMenuContent>
        </NavigationMenuItem>
    )
}

function getComplexStructure(item: any, i: number, dictionary: GenericObject) {
    return (
        <NavigationMenuItem key={item.name + '-' + item.href + '-' + i}>
            <NavigationMenuTrigger className={((pathName == item?.specialChild.href) || (item.specialChild.hrefPattern == router.pathname)) ? 'active bg-primary text-primary-foreground' : ''}>{dictionary?.navigationMenu[item.name] ?? item.label}</NavigationMenuTrigger>
            <NavigationMenuContent>
                <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
                    {
                        (item?.specialChild) ? (
                            <li className="row-span-3">
                                <NavigationMenuLink asChild>
                                    <Link
                                        className={cn("flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md")}
                                        href={item?.specialChild?.href}
                                        onClick={(() => { routeHandler(item?.specialChild) })}
                                    >
                                        <Icons.logo className="h-6 w-6" />
                                        <div className="mb-2 mt-4 text-lg font-medium">
                                            {dictionary?.navigationMenu[item?.specialChild?.name] ?? item?.specialChild?.label}
                                        </div>
                                        <p className="text-sm leading-tight text-muted-foreground">
                                            {item?.specialChild?.description}
                                        </p>
                                    </Link>
                                </NavigationMenuLink>
                            </li>
                        ) : null
                    }
                    {item?.children?.map((child: LinkProps) => (
                        <ListItem
                            key={child.name}
                            title={dictionary?.navigationMenu[child.name] ?? child.label}
                            onClick={(() => { routeHandler(child); router.push(child.href); })}
                        >
                            {child.description}
                        </ListItem>
                    ))}
                </ul>
            </NavigationMenuContent>
        </NavigationMenuItem>
    )
}

function getBaseStructure(item: NavigationItemsProps, i: number, dictionary: GenericObject) {
    return (
        <NavigationMenuItem key={item.name + '-' + item.href + '-' + i}>
            <Link href={item.href ?? '#'} className={((pathName == item?.href) || (router.pathName == item?.hrefPattern)) ? 'active bg-primary text-primary-foreground' : ''} legacyBehavior passHref>
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    {dictionary?.navigationMenu[item.name] ?? item.label}
                </NavigationMenuLink>
            </Link>
        </NavigationMenuItem>
    )
}

function getStructure(item: NavigationItemsProps, index: number, dictionary: GenericObject) {
    let result = null;
    switch (true) {
        case (!item.specialChild && !item.href && !!item.children):
            result = getSimpleStructure(item, index, dictionary);
            break;
        case (!!item.specialChild && !item.href && !!item.children):
            result = getComplexStructure(item, index, dictionary);
            break;
        case (!item.specialChild && !!item.href && !item.children):
            result = getBaseStructure(item, index, dictionary);
            break;
        default:
            result = getBaseStructure(item, index, dictionary);
            break;
    }
    return result;
}

let globalDataProps: GenericObject | null = null;
export function getNavMenuStates() {
    return globalDataProps;
}

export default function NavMenu({ ...props }) {

    const [dataProps, setDataProps] = React.useState<GenericObject>(props);

    const { dictionary } = React.useContext(TranslationsContext);

    router = useRouter();

    pathName = usePathname();

    globalDataProps = { dataProps, setDataProps };

    React.useEffect(() => {
        console.log(dataProps)
    }, [dataProps]);

    return (
        <NavigationMenu>
            <NavigationMenuList>
                {
                    (dataProps?.navigationList)?.map((item: NavigationItemsProps, i: number) => {
                        return getStructure(item, i, dictionary);
                    })
                }
            </NavigationMenuList>
        </NavigationMenu>
    )
}

const ListItem = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                        className
                    )}
                    {...props}
                >
                    <div className="text-sm font-medium leading-none">{title}</div>
                    <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                        {children}
                    </p>
                </a>
            </NavigationMenuLink>
        </li>
    )
})

ListItem.displayName = "ListItem"
