"use client"

import { ColumnDef } from "@tanstack/react-table"

import { Badge } from "@/components/ui/badge"
import { Checkbox } from "@/components/ui/checkbox"
import { DataTableColumnHeader } from "./data-table-column-header"
import { DataTableRowActions } from "./data-table-row-actions"

import { CheckIcon, CircleCheckIcon, LoaderCircleIcon, Option, UsersIcon } from "lucide-react"
import { GenericObject } from "@/models/UtilsModel"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { createNavigationMenuScope } from "@radix-ui/react-navigation-menu"
import { cn } from "@/lib/utils"
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"

let tableColumnsDictionary: GenericObject | null = null;
let colCfg: GenericObject | null = null;

/* export let rowActions = {
    onPreferenceChange: (rowOrigin: Profile) => { console.log('onPreferenceChange ===> ', rowOrigin) },
    onPersonificationChange: (rowOrigin: Profile) => {
        rowOrigin.personificated = !rowOrigin.personificated;
        if (rowOrigin.personificated) {
            setPersonification({ name: rowOrigin.name, email: rowOrigin.email, role: rowOrigin.role })
        } else {
            ejectPersonification();
        }
    }
} */

const getCheckboxRowTemplate = (columnItem: any) => {
    return {
        id: "select",
        header: ({ table }: any) => (
            <Checkbox
                checked={
                    table.getIsAllPageRowsSelected() ||
                    (table.getIsSomePageRowsSelected() && "indeterminate")
                }
                onCheckedChange={(value: any) => table.toggleAllPageRowsSelected(!!value)}
                aria-label="Select all"
                className="translate-y-[2px] data-[state=checked]:bg-primary"
            />
        ),
        cell: ({ row }: any) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value: any) => row.toggleSelected(!!value)}
                aria-label="Select row"
                className="translate-y-[2px] data-[state=checked]:bg-primary"
                onClick={(e: any) => { e.stopPropagation() }}
            />
        ),
        enableSorting: false,
        enableHiding: false,
    }
}

const getBaseTemplate = (columnItem: any) => {
    return {
        accessorKey: columnItem.name,
        header: ({ column }: any) => (
            <DataTableColumnHeader column={column} title={tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label} />
        ),
        cell: ({ row }: any) => {
            const label = tableColumnsDictionary?.[columnItem.name]?.options?.[row.getValue(columnItem.name)]?.label ?? row.getValue(columnItem.name);

            return (
                <div className="flex items-center space-x-1">
                    {
                        (columnItem.icons) &&
                        columnItem.icons.map((iconItem: any, index: number) => {
                            if ((iconItem.type == 'button') || !iconItem.type) {
                                return (
                                    iconItem?.onClick ?
                                        <Tooltip key={iconItem.name + '-' + index} delayDuration={0} disableHoverableContent={false}>
                                            <TooltipTrigger>
                                                <Badge
                                                    variant="outline"
                                                    key={iconItem.name + '-' + index}
                                                    className={"cursor-pointer"}
                                                    onClick={async (e) => {
                                                        e.stopPropagation();
                                                        await iconItem.onClick(row.original, iconItem.name);
                                                    }}
                                                >
                                                    {
                                                        ((row.original?.status?.state === 'pending') && (iconItem.name === row.original?.status?.name)) &&
                                                        <LoaderCircleIcon name={iconItem.name} size={iconItem.size ?? 18} className={'stroke-2 animate-spin stroke-yellow-500'} />
                                                    }
                                                    {
                                                        ((row.original?.status?.state === 'success') && (iconItem.name === row.original?.status?.name)) &&
                                                        <CircleCheckIcon name={iconItem.name} size={iconItem.size ?? 18} className={'stroke-2 fill-green-600 stroke-secondary scale-125'} />
                                                    }
                                                    {
                                                        ((!row.original?.status?.state) || !(iconItem.name === row.original?.status?.name)) &&
                                                        <iconItem.icon name={iconItem.name} size={iconItem.size ?? 18} className={iconItem.className + ' ' + ((row.original[iconItem.classNameConditionKey]) && iconItem.classNameActive)} />
                                                    }
                                                </Badge>
                                            </TooltipTrigger>
                                            {
                                                iconItem.tooltip &&
                                                <TooltipContent side="bottom" className={"flex items-center gap-4" + " " + (tableColumnsDictionary?.['actions']?.options?.[iconItem.name]?.tooltip) ? '' : 'display-none'}>
                                                    {
                                                        (() => {
                                                            const tooltip = tableColumnsDictionary?.['actions']?.options?.[iconItem.name]?.tooltip ?? iconItem.tooltipLabel ?? '';
                                                            const disabledTooltip = tableColumnsDictionary?.['actions']?.options?.[iconItem.name]?.disabledTooltip ?? iconItem.disableTooltipLabel ?? '';
                                                            return !(row.original[iconItem.classNameConditionKey]) ? tooltip : disabledTooltip;
                                                        })()
                                                    }
                                                </TooltipContent>
                                            }
                                        </Tooltip>
                                        :
                                        <iconItem.icon key={iconItem.name + '-' + index} name={iconItem.name} size={iconItem.size ?? 18} className={'mr-1 ' + iconItem.className + ' ' + ((row.original[iconItem.classNameConditionKey]) && iconItem.classNameActive)} />
                                )
                            }
                            if ((iconItem.type == 'combobox')) {
                                return getColumnBadge(row, iconItem, index)
                            }
                        })
                    }
                    <span className="font-medium">
                        <Tooltip delayDuration={0} disableHoverableContent={false}>
                            <TooltipTrigger
                                className={cn(
                                    columnItem.className,
                                    "truncate"
                                )}

                                style={columnItem.style}
                            >
                                {label}
                            </TooltipTrigger>
                            <TooltipContent>
                                {label}
                            </TooltipContent>
                        </Tooltip>
                    </span>
                </div>
            )
        },
        enableSorting: columnItem.enableSorting ?? false,
        enableHiding: columnItem.enableHiding ?? false,
        filterFn: (row: any, id: any, value: any) => {
            const rowValue = tableColumnsDictionary?.[columnItem.name]?.options?.[row.getValue(columnItem.name)]?.label ?? row.getValue(columnItem.name);
            //console.log(rowValue.toLowerCase(), (value.toLowerCase()))
            return rowValue.toLowerCase().includes(value.toLowerCase());
        },
    }
}

const getFilterTemplate = (columnItem: any) => {
    return {
        accessorKey: columnItem.name,
        header: ({ column }: any) => (
            <DataTableColumnHeader column={column} title={tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label} />
        ),
        cell: ({ row }: any) => {
            const option: GenericObject = columnItem.options.find(
                (option: GenericObject) => option.name === row.getValue(columnItem.name)
            )

            if (!option) {
                return null
            }

            return (
                <div className="flex w-[100px] items-center">
                    {option.icon && (
                        <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{tableColumnsDictionary?.[columnItem.name]?.options?.[option.name]?.label ?? option.label}</span>
                </div>
            )
        },
        filterFn: (row: any, id: any, value: any) => {
            const rowValue = row.getValue(id);
            const filterFn = colCfg?.columns?.filter((col: any) => col.name == id)?.[0].filterFn;

            if (filterFn) {
                return filterFn(row, id, value);
            }

            return value.includes(rowValue);

        },
        enableSorting: columnItem.enableSorting ?? false,
        enableHiding: columnItem.enableHiding ?? false
    }
}

const getDropdownFilterTemplate = (columnItem: any) => {
    return {
        accessorKey: columnItem.name,
        header: ({ column }: any) => (
            <DataTableColumnHeader column={column} title={tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label} />
        ),
        cell: ({ row }: any) => {
            const option: GenericObject = columnItem.options.find(
                (option: GenericObject) => option.name === row.getValue(columnItem.name)
            )

            if (!option) {
                return null
            }

            return (
                <div className="flex w-[100px] items-center">
                    {option.icon && (
                        <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{tableColumnsDictionary?.[columnItem.name]?.options?.[option.name]?.label ?? option.label}</span>
                </div>
            )
        },
        filterFn: (row: any, id: any, value: any) => {
            const rowValue = row.getValue(id);
            const filterFn = colCfg?.columns?.filter((col: any) => col.name == id)?.[0].filterFn;

            if (filterFn) {
                return filterFn(row, id, value);
            }

            return value.includes(rowValue);

        },
        sortingFn: (
            rowA: any,
            rowB: any,
            columnId: any
        ) => {
            const sortingFn = colCfg?.columns?.filter((col: any) => col.name == columnId)?.[0]?.sortingFn ?? null;

            if (sortingFn) {
                return sortingFn(rowA, rowB, columnId);
            }

            const numA = rowA.getValue(columnId).count;
            const numB = rowB.getValue(columnId).count;

            return numA < numB ? 1 : numA > numB ? -1 : 0;
        },
        enableSorting: columnItem.enableSorting ?? false,
        enableHiding: columnItem.enableHiding ?? false
    }
}

const getActionTemplate = (columnItem: any) => {
    return {
        id: columnItem.name,
        header: ({ column }: any) => (
            <DataTableColumnHeader column={column} title={tableColumnsDictionary?.[columnItem.name]?.label ?? columnItem.label} />
        ),
        cell: ({ row }: any) => {
            return (
                <div className="flex items-center space-x-1">
                    {
                        columnItem.actions.filter((action: any) => !action.subMenu).map((action: any, index: number) => {
                            if ((action.type == 'button') || !action.type) {
                                return (
                                    <Tooltip key={action.name + '-' + index} delayDuration={0}>
                                        <TooltipTrigger>
                                            <Badge variant="outline" key={action.name + '-' + index} className={"cursor-pointer"} onClick={(e) => { action.onClick(row.original, action.name); e.stopPropagation(); }}>
                                                {
                                                    ((row.original?.status?.state === 'pending') && (action.name === row.original?.status?.name)) &&
                                                    <LoaderCircleIcon name={action.name} size={action.size ?? 18} className={'stroke-2 animate-spin stroke-yellow-500'} />
                                                }
                                                {
                                                    ((row.original?.status?.state === 'success') && (action.name === row.original?.status?.name)) &&
                                                    <CircleCheckIcon name={action.name} size={action.size ?? 18} className={'stroke-2 fill-green-600 stroke-secondary scale-125'} />
                                                }
                                                {
                                                    ((!row.original?.status?.state) || !(action.name === row.original?.status?.name)) &&
                                                    <action.icon name={action.name} size={action.size ?? 18} className={action.className + ' ' + ((row.original[action.classNameConditionKey]) && action.classNameActive)} />
                                                }
                                            </Badge>
                                        </TooltipTrigger>
                                        <TooltipContent side="bottom" className="flex items-center gap-4">
                                            {
                                                (() => {
                                                    const tooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.tooltip ?? '';
                                                    const disabledTooltip = tableColumnsDictionary?.['actions']?.options?.[action.name]?.disabledTooltip ?? '';
                                                    return !(row.original[action.classNameConditionKey]) ? tooltip : disabledTooltip;
                                                })()
                                            }
                                        </TooltipContent>
                                    </Tooltip>
                                )
                            }
                            if ((action.type == 'combobox')) {
                                return getColumnBadge(row, action, index)
                            }
                        })
                    }
                    <DataTableRowActions row={row} actions={columnItem.actions.filter((action: any) => action.subMenu)} />
                </div>
            )
        }
    }
}

const getColumnBadge = (row: any, action: any, index: number) => {
    return (<DropdownMenu key={action.name + '-' + index}>
        <Tooltip>
            <TooltipTrigger asChild>
                <DropdownMenuTrigger>
                    <Badge variant="outline" key={action.name + '-' + index} className={"cursor-pointer"}>
                        <action.icon name={action.name} size={action.size ?? 18} className={action.className + ' ' + ((row.original[action.classNameConditionKey]) && action.classNameActive)} />
                    </Badge>
                </DropdownMenuTrigger>
            </TooltipTrigger>
            <TooltipContent side="top" className="flex items-center gap-4">
                {action?.tooltip ? action?.tooltip : ''}
            </TooltipContent>
        </Tooltip>
        <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
            <Command>
                <CommandInput placeholder={action?.placeholder ?? "Cerca tra i clienti..."} />
                <CommandList>
                    <CommandEmpty>no data</CommandEmpty>
                    <CommandGroup>
                        {
                            action?.getOptions(row?.original?.id)?.map((option: any, index: number) => (
                                <CommandItem
                                    key={option.name + '-' + index}
                                    value={option.name}
                                    onSelect={(e: any) => {
                                        action.onOptionSelect(row?.original?.id, option.id);
                                        /* e.stopPropagation(); */
                                    }}
                                >
                                    <CheckIcon
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            action?.getCurrentOption(row?.original?.id)?.id === option?.id ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                    {option.name}
                                </CommandItem>
                            ))
                        }
                    </CommandGroup>
                </CommandList>
            </Command>
        </DropdownMenuContent>
    </DropdownMenu>)
}

const getColumnTemplate: (columnItem: any) => ColumnDef<any> | null = (columnItem: any) => {
    switch (columnItem.type) {
        case 'checkboxRow':
            return getCheckboxRowTemplate(columnItem);
        case 'base':
            return getBaseTemplate(columnItem);
        case 'filter':
            return getFilterTemplate(columnItem);
        case 'dropdown-filter':
            return getDropdownFilterTemplate(columnItem)
        case 'action':
            return getActionTemplate(columnItem);
        default:
            return getBaseTemplate(columnItem);
    }
}

export const getColumns = (columnsCfg: any, dictionary: GenericObject | null = null) => {
    colCfg = columnsCfg ?? {};
    tableColumnsDictionary = dictionary;
    let columns: ColumnDef<any>[] = [];
    if (columnsCfg.checkboxRow && (columnsCfg?.massiveActions?.length > 0)) {
        const columnTemplate = getColumnTemplate({ type: 'checkboxRow' });
        columnTemplate ? columns.push(columnTemplate) : null
    }

    if (columnsCfg.columns) {
        columnsCfg.columns.forEach((column: GenericObject) => {
            const columnTemplate = getColumnTemplate(column);
            columnTemplate ? columns.push(columnTemplate) : null;
        })
    }

    return columns;
}
