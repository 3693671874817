"use client"

import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Row } from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

interface DataTableRowActionsProps<TData> {
    row: Row<TData>,
    actions: any
}

export function DataTableRowActions<TData>({
    row,
    actions
}: DataTableRowActionsProps<TData>) {
    /* const profile = profileSchema?.parse(row?.original) */

    return (
        <DropdownMenu onOpenChange={(isOpened: boolean) => { }}>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                    <span className="sr-only">Open menu</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-max[260px]">
                {
                    actions.map((action: any, index: number) => {
                        const original: any = row.original;
                        const conditionLabel = (original?.[action?.classNameConditionKey]) ? action.conditionLabel : action.label;
                        return (
                            <DropdownMenuItem key={action.name + '-' + index} onClick={(e) => { e.stopPropagation(); action.onClick(row.original); }}>
                                {conditionLabel}
                            </DropdownMenuItem>
                        )
                    })
                }

                {/* <DropdownMenuItem onClick={() => { actions.onPreferenceChange(profile) }}>{!profile.preferred ? 'Aggiungi ai preferiti' : 'Rimuovi dai preferiti'}</DropdownMenuItem>
                <DropdownMenuItem onClick={() => { actions.onPersonificationChange(profile) }}>{!profile.personificated ? 'Personifica' : 'Esci dalla personificazione'}</DropdownMenuItem> */}
                {/* <DropdownMenuSeparator /> */}
                {/* <DropdownMenuSub>
                    <DropdownMenuSubTrigger>Labels</DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                        <DropdownMenuRadioGroup value={profile.label}>
                            {labels.map((label) => (
                                <DropdownMenuRadioItem key={label.value} value={label.value}>
                                    {label.label}
                                </DropdownMenuRadioItem>
                            ))}
                        </DropdownMenuRadioGroup>
                    </DropdownMenuSubContent>
                </DropdownMenuSub>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                    Delete
                    <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
                </DropdownMenuItem>*/}
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
